/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration

==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
    primary: '#345a7a',
    //secondary: '#e56781',
    secondary: '#663069',
    success: '#4f9b7e',
    danger: '#e56781',
    warning: '#e56781',
    dark: '#1E1E1E',
    grey: '#e6e6e6',
    pallet1: '#FF8B87',
    pallet2: '#6EBEFF',
    pallet3: '#F8FF54',
    pallet4: '#AEB344',
    lightGrey: '#eff2f7',
}

// CONFIGS
const themeConfig = {
    disableCustomizer: false, // options[Boolean] : true, false(default)
    disableThemeTour: false, // options[Boolean] : true, false(default)
    footerType: 'hidden', // options[String]  : static(default) / sticky / hidden
    hideScrollToTop: false, // options[Boolean] : true, false(default)
    mainLayoutType: 'vertical', // options[String]  : vertical(default) / horizontal
    navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
    navbarType: 'static', // options[String]  : floating(default) / static / sticky / hidden
    routerTransition: 'fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
    rtl: false, // options[Boolean] : true, false(default)
    sidebarCollapsed: false, // options[Boolean] : true, false(default)
    theme: 'light', // options[String]  : "light"(default), "dark", "semi-dark"

    // Not required yet - WIP
    userInfoLocalStorageKey: 'userInfo',
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl })

export default themeConfig
