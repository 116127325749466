import { http } from '@/services'
import { httpWill } from '@/services'

import jwt_decode from 'jwt-decode'
import router from '@/router'
import axios from 'axios'
import { httpBlob } from '../services'

const actions = {
    // Vertical NavMenu

    updateVerticalNavMenuWidth({ commit }, width) {
        commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest

    updateStarredPage({ commit }, payload) {
        commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar

    arrangeStarredPagesLimited({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
    toggleContentOverlay({ commit }) {
        commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
        commit('UPDATE_THEME', val)
    },

    // authentication

    setHttpHeaders({ getters }) {
        const baseHTTP = {
            baseUrl: getters.endpoints.baseUrl,
            headers: {
                Authorization: `JWT ${getters.tokens.access}`,
                'Content-Type': 'application/json',
            },
        }
        const baseHTTPBlob = {
            baseUrl: getters.endpoints.baseUrl,
            headers: {
                Authorization: `JWT ${getters.tokens.access}`,
                'Content-Type': 'application/json',
                'Response-Type': 'blob',
            },
        }
        http.defaults.headers = baseHTTP.headers
        httpBlob.defaults.header = baseHTTPBlob.headers
        httpWill.defaults.headers = baseHTTP.headers
    },
    logout({ commit }) {
        commit('removeTokens')
        commit('clearInitialise')
        router.push('/login')
    },

    obtainToken({ commit, getters, dispatch }, data) {
        const payload = {
            email: data.email,
            password: data.password,
        }
        axios
            .post(getters.endpoints.obtainJWT, payload)
            .then((response) => {
                commit('accessToken', response.data.access)
                commit('refreshToken', response.data.refresh)
                dispatch('setHttpHeaders')

                try {
                    dispatch('userFetch')
                } catch (error) {
                    throw error
                }

                router.push('/')
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    commit('loginError', error.response.data)
                else commit('loginError', error)
                throw error
            })
    },
    async refreshToken({ commit, getters, dispatch }) {
        const decoded = jwt_decode(getters.tokens.refresh)
        const exp = decoded.exp
        const now = Date.now() / 1000

        if (!getters.tokens.refresh || exp - now <= 0) {
            dispatch('logout')
            return
        }

        const payload = {
            refresh: getters.tokens.refresh,
        }

        const refreshingCall = axios
            .post(getters.endpoints.refreshJWT, payload)
            .then((response) => {
                commit('accessToken', response.data.access)
                dispatch('setHttpHeaders')
                try {
                    dispatch('userFetch')
                } catch (error) {
                    throw error
                }
                return Promise.resolve(true)
            })
            .catch((error) => {
                console.log(error)
                return false
            })
        return refreshingCall
    },
    async inspectToken({ dispatch, getters, commit }) {
        const token = getters.tokens.access

        if (token) {
            if (
                http.defaults &&
                http.defaults.headers.Authorization !== `JWT ${token}`
            ) {
                dispatch('setHttpHeaders')
            }

            const decoded = jwt_decode(token)
            const exp = decoded.exp
            const now = Date.now() / 1000

            if (exp - now < 300) {
                // less than 5 minutes to go 300
                let response = dispatch('refreshToken')
                return response
            } else if (exp - now < 86400) {
                // 1 day
                // DO NOTHING, DO NOT REFRESH
                if (!getters.user) {
                    try {
                        dispatch('userFetch')
                    } catch (error) {
                        throw error
                    }
                }
            } else {
                router.push('/login').catch((err) => {})
            }
        } else {
            router.push('/login').catch((err) => {})
        }
        if (localStorage.getItem('notifications'))
            commit(
                'notificationsAdd',
                JSON.parse(localStorage.getItem('notifications') || [])
            )
        return Promise.resolve(true)
    },

    // app logic

    initialise({ dispatch, getters }) {
        if (!getters.willsToApprove) dispatch('willsToApproveFetch')
        if (!getters.willsToCheck) dispatch('willsToCheckFetch')
        if (!getters.willsToContact) dispatch('willsToContactFetch')
        if (!getters.willsToPrint) dispatch('willsToPrintFetch')
        if (!getters.willsThisMonth) dispatch('willsThisMonth')
        if (!getters.emailsFromUsers) dispatch('emailsFromUsers')
        if (!getters.adviceToContact) dispatch('adviceToContact')
        if (!getters.userData && getters.user)
            dispatch('userDataFetch', getters.user.id)
        if (!getters.todaysAppointments) dispatch('todaysAppointmentsFetch')
        if (!getters.failedPayments) dispatch('failedPaymentsFetch')
        if (!getters.newFeatures) dispatch('newFeaturesFetch')
        if (localStorage.getItem('notifications') === null)
            localStorage.setItem('notifications', [])
    },
    userFetch({ commit, dispatch }) {
        http.get('user')
            .then((response) => {
                commit('user', {
                    authUser: response.data,
                    isAuthenticated: true,
                })
                dispatch('initialise')
            })
            .catch((error) => {
                console.log(error)
            })
    },
    willsToApproveFetch({ commit }) {
        http.get('wills_to_approve')
            .then((response) => {
                commit('willsToApprove', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    willsToCheckFetch({ commit }) {
        http.get('wills_to_check')
            .then((response) => {
                commit('willsToCheck', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    willsToContactFetch({ commit }) {
        http.get('wills_to_contact')
            .then((response) => {
                commit('willsToContact', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    willsToPrintFetch({ commit }) {
        http.get('wills_to_print')
            .then((response) => {
                commit('willsToPrint', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    willsThisMonth({ commit }, days = 7) {
        http.get('wills_last_28_days', { params: { days: days } })
            .then((response) => {
                commit('willsThisMonth', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    emailsFromUsers({ commit }) {
        http.get('emails_from_users')
            .then((response) => {
                commit('emailsFromUsers', response.data)
            })
            .catch((error) => console.log(error))
    },
    adviceToContact({ commit }) {
        http.get('advice_to_contact')
            .then((response) => {
                commit('adviceToContact', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    userDataFetch({ commit }, user_id) {
        http.get('user_staff_detail', { params: { id: user_id } })
            .then((response) => {
                commit('userData', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    todaysAppointmentsFetch({ commit }, date = null) {
        var requestDate = null
        if (date) requestDate = date.toDateString()
        http.get('todays_advice_appointments', {
            params: { date: requestDate },
        })
            .then((response) => {
                commit('todaysAdviceAppointments', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    failedPaymentsFetch({ commit }, range = null) {
        var requestRange = null
        if (range)
            requestRange = {
                start: range.start,
                end: range.end,
            }
        http.get('failed_payments', { params: requestRange })
            .then((response) => {
                commit('failedPayments', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    notificationFetch({ commit }) {
        http.get('notifications')
            .then((response) => {
                commit('notificationsAdd', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    newFeaturesFetch({ commit }) {
        http.get('new_features')
            .then((response) => {
                commit('newFeatures', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
}

export default actions
