import Vue from 'vue'
import { http } from '../services'

const mutations = {
    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu

    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
        state.isVerticalNavMenuActive = value
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val
    },
    UPDATE_MAIN_LAYOUT_TYPE(state, val) {
        state.mainLayoutType = val
    },
    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
        state.verticalNavMenuItemsMin = val
    },
    UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
        state.verticalNavMenuWidth = width
    },

    // VxAutoSuggest

    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList['pages'].data.findIndex(
            (item) => item.url == payload.url
        )

        // update the main list
        state.navbarSearchAndPinList['pages'].data[index].is_bookmarked =
            payload.val

        // if val is true add it to starred else remove
        if (payload.val) {
            state.starredPages.push(
                state.navbarSearchAndPinList['pages'].data[index]
            )
        } else {
            // find item index from starred pages
            const index = state.starredPages.findIndex(
                (item) => item.url == payload.url
            )

            // remove item using index
            state.starredPages.splice(index, 1)
        }
    },

    UPDATE_SEARCH_RESULTS(state, payload) {
        Vue.set(state.navbarSearchAndPinList, payload.value, payload.data)
    },

    // Navbar-Vertical

    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10)
        state.starredPages = list.concat(starredPagesMore)
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10]
        const starredPagesLimited = state.starredPages.slice(0, 10)
        state.starredPages = starredPagesLimited.concat(list)

        state.starredPages.slice(0, 10).map((i) => {
            if (list.indexOf(i) > -1) downToUp = true
        })

        if (!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited)
        }
    },

    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val
    },
    UPDATE_THEME(state, val) {
        state.theme = val
    },
    UPDATE_WINDOW_WIDTH(state, width) {
        state.windowWidth = width
    },
    UPDATE_WINDOW_SCROLL_Y(state, val) {
        state.scrollY = val
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        // Get Data localStorage
        let userInfo =
            JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

        for (const property of Object.keys(payload)) {
            if (payload[property] != null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    user(state, { authUser, isAuthenticated }) {
        Vue.set(state, 'user', authUser)
        Vue.set(state, 'isAuthenticated', isAuthenticated)
    },

    // authentication

    loginError(state, message) {
        state.loginError = message
    },
    accessToken(state, newToken) {
        localStorage.setItem('accessToken', newToken)
        state.tokens.access = newToken
    },
    refreshToken(state, newToken) {
        localStorage.setItem('refreshToken', newToken)
        state.tokens.refresh = newToken
    },
    removeTokens(state) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        http.defaults.headers.Authorization = null
        state.tokens.access = null
        state.tokens.refresh = null
    },

    // app logic

    clearInitialise(state) {
        state.user = null
        state.willsToApprove = null
        state.willsToCheck = null
        state.willsToContact = null
        state.willsToPrint = null
    },
    notificationsAdd(state, data) {
        var amount = data.length > 20 ? 20 : data.length
        for (var i = 0; i < amount; i++) {
            state.notifications.push(data[i])
        }
        state.notifications.sort((a, b) => new Date(b.date) - new Date(a.date))
        localStorage.setItem(
            'notifications',
            JSON.stringify(state.notifications)
        )
    },
    notificationsClear(state, data) {
        state.notifications = []
        localStorage.setItem('notifications', [])
    },
    willsToApprove(state, data) {
        state.willsToApprove = data
    },
    willsToCheck(state, data) {
        state.willsToCheck = data
    },
    willsToContact(state, data) {
        state.willsToContact = data
    },
    willsToPrint(state, data) {
        state.willsToPrint = data
    },
    willsThisMonth(state, data) {
        state.willsThisMonth = data
    },
    emailsFromUsers(state, data) {
        state.emailsFromUsers = data
    },
    adviceToContact(state, data) {
        state.adviceToContact = data
    },
    userData(state, data) {
        state.userData = data
    },
    todaysAdviceAppointments(state, data) {
        state.todaysAdviceAppointments = data
    },
    failedPayments(state, data) {
        state.failedPayments = data
    },
    newFeatures(state, data) {
        state.newFeatures = data
    },
}

export default mutations
