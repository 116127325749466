const getters = {
    windowBreakPoint: (state) => {
        if (state.windowWidth >= 1200) return 'xl'
        else if (state.windowWidth >= 992) return 'lg'
        else if (state.windowWidth >= 768) return 'md'
        else if (state.windowWidth >= 576) return 'sm'
        else return 'xs'
    },
    endpoints: (state) => {
        return state.endpoints
    },
    tokens: (state) => {
        return state.tokens
    },
    loginError: (state) => {
        return state.loginError
    },
    user: (state) => {
        return state.user
    },
    notifications: (state) => {
        return state.notifications
    },
    willsToApprove: (state) => {
        return state.willsToApprove
    },
    willsToCheck: (state) => {
        return state.willsToCheck
    },
    willsToContact: (state) => {
        return state.willsToContact
    },
    willsToPrint: (state) => {
        return state.willsToPrint
    },
    willsThisMonth: (state) => {
        return state.willsThisMonth
    },
    emailsFromUsers: (state) => {
        return state.emailsFromUsers
    },
    adviceToContact: (state) => {
        return state.adviceToContact
    },
    userData: (state) => {
        return state.userData
    },
    todaysAdviceAppointments: (state) => {
        return state.todaysAdviceAppointments
    },
    failedPayments: (state) => {
        return state.failedPayments
    },
    newFeatures: (state) => {
        return state.newFeatures
    },
}

export default getters
