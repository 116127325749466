import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    mainLayoutType: themeConfig.mainLayoutType || 'vertical',
    navbarSearchAndPinList: navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: null,
    theme: themeConfig.theme || 'light',
    themePrimaryColor: colors.primary,
    windowWidth: null,

    // authentication

    isAuthenticated: false,
    loginError: null,
    tokens: {
        access: localStorage.getItem('accessToken'),
        refresh: localStorage.getItem('refreshToken'),
    },
    endpoints: {
        baseUrl:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/management/api/v2/'
                : 'http://127.0.0.1:8000/management/api/v2/',
        obtainJWT:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/management/api/v2/token/'
                : 'http://127.0.0.1:8000/management/api/v2/token/',
        refreshJWT:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/management/api/v2/token/refresh/'
                : 'http://127.0.0.1:8000/management/api/v2/token/refresh/',
    },

    // app logic

    user: null,
    notifications: [],
    willsToApprove: null,
    willsToCheck: null,
    willsToContact: null,
    willsToPrint: null,
    willsThisMonth: null,
    emailsFromUsers: null,
    adviceToContact: null,
    userData: null,
    todaysAdviceAppointments: null,
    failedPayments: null,
    newFeatures: null,
}

export default state
